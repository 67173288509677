<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="12">
          <div class="d-flex">
            <feather-icon
              icon="UserIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              Datos personales
            </h4>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col md="6">
          <b-form-group
            label="N° Documento de identidad"
            label-for="mc-first-name"
          >
            <b-form-input
              size="sm"
              readonly
              value="48315690"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="N° de historia clinica"
            label-for="mc-first-name"
          >
            <b-form-input
              size="sm"
              readonly
              value="UNIA-DIBU-23"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Apellido paterno"
            label-for="mc-first-name"
          >
            <b-form-input
              size="sm"
              readonly
              value="ESCANDON"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Apellido materno"
            label-for="mc-first-name"
          >
            <b-form-input
              size="sm"
              readonly
              value="MUNGUIA"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Primer nombre"
            label-for="mc-first-name"
          >
            <b-form-input
              size="sm"
              readonly
              value="SAUL ANGEL"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row>
        <b-col md="12">
          <div class="d-flex">
            <feather-icon
              icon="FileTextIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              Atención
            </h4>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col md="6">
          <b-form-group
            label="Procedimiento(s)"
          >
            <v-select
              v-model="selected"
              :dir="dir"
              multiple
              label="title"
              :options="option"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Otros"
            label-for="mc-first-name"
          >
            <b-form-textarea
              id="textarea-large"
              size="lg"
              placeholder="opcional"
            />
          </b-form-group>
        </b-col>
        <b-col md="12"><br><br>
          <b-button
            block
            variant="primary"
          >
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BCard, BFormTextarea, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow, BCol, BFormGroup, BFormInput, BCard, BFormTextarea, vSelect, BButton,
  },
  data() {
    return {
      dir: 'ltr',
      selected: [{ title: 'Aplicación de inyección' }],
      option: [
        { title: 'Aplicación de inyección' },
        { title: 'Curacción de herida' },
        { title: 'Lavado de herida' },
      ],
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
